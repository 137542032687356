<template>
  <v-dialog v-model="visible" max-width="500px">
    <v-card class="editorPadding">
      <Header @close="close" />

      <v-card-title>
        Please enter a password
      </v-card-title>

      <p><v-text-field ref="refPassword" v-model="password" placeholder="Password" /></p>

      <CommonBtn 
        :isShowDeleteBtn="false"
        @save="clickSave" 
        @cancel="clickCancel"
        />
    </v-card>
  </v-dialog>
</template>
<script>
import Header from "./comp/Header.vue";
import CommonBtn from "./medicalInfo/comp/CommonBtn.vue";
export default {
  components: {
    Header,
    CommonBtn
  },
  data() {
    return {
      visible: false,
      password: '',
    };
  },
  methods: {
    showModal() {
      this.visible = true;
      setTimeout(() => this.$refs.refPassword.focus(), 300);
    },
    close() {
      this.visible = false;
    },
    clickCancel() {
      this.visible = false;
      this.$emit("clickCancel")
    },
    clickSave(e) {
      console.log(e);
      this.visible = false;
    },
  },
};
</script>
<style lang="">
</style>