<template>
  <div class="step-body question-ele mar-bo100" v-if="targetQuestion">
    <h2 class="step-title">{{ targetQuestion && questionNO }}.{{ targetQuestion && targetQuestion.question }}</h2>
    <div
      class="banner single b-wt mar-bo10"
      v-for="(item, index) in targetQuestion.element"
      :key="index"
      :class="{ selected: index === selectedNum }"
      @click="clickAnswer(item, index)"
    >
      {{ item }}
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  props: {
    questionSet: {
      type: [String],
    },
    targetQuestion: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      questionNO: 1,
      selectedNum: -1,
    };
  },
  watch: {
    targetQuestion(val) {
      console.log('targetQuestion', val);
      if (this.mPatient.userSurvey === null) {
        this.questionNO = 1;
        this.selectedNum = -1;
        return
      }
      // this.selectedNum = -1;
      this.questionNO = val.questionNO + 1;
      this.selectedNum =
        this.mPatient !== null &&
        this.mPatient.userSurvey !== null &&
        this.mPatient.userSurvey.answerSet.length > 0 &&
        this.mPatient.userSurvey.answerSet[val.questionNO]
          ? this.mPatient.userSurvey.answerSet[val.questionNO] - 1
          : -1;
    },
  },
  computed: {
    ...mapState(['mPatient']),
  },
  methods: {
    clickAnswer(item, index) {
      this.selectedNum = index;
      this.$emit('selectedComplete', index + 1);
    },
  },
};
</script>

<style lang="scss">
.step-body.question-ele {
  width: 100%;
  min-height: 60px;
  position: relative;
  .step-title {
    font-size: 24px;
    margin-top: 90px;
    margin-bottom: 40px;
  }
  .step-count {
    color: #ffffff;
    font-size: 24px;
  }
  .banner {
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    height: 4rem;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    background: var(--white, #fff);
    box-shadow: 2px 1px 10px 1px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    &.selected {
      background: #69afff !important;
      color: #ffffff;
    }
  }
  .guide {
    width: 80%;
    color: #ffffff;
    text-align: left;
    margin: 0 auto;
  }
  &.body-type {
    .banner {
      &:nth-child(2) {
        background-image: url(https://icaregc.s3.ap-northeast-2.amazonaws.com/appImgs/img/sample/body_type1.png);
        background-size: auto 55px;
        background-position: 10px;
        background-repeat: no-repeat;
      }
      &:nth-child(3) {
        background-image: url(https://icaregc.s3.ap-northeast-2.amazonaws.com/appImgs/img/sample/body_type2.png);
        background-size: auto 55px;
        background-position: 10px;
        background-repeat: no-repeat;
      }
      &:nth-child(4) {
        background-image: url(https://icaregc.s3.ap-northeast-2.amazonaws.com/appImgs/img/sample/body_type3.png);
        background-size: auto 55px;
        background-position: 10px;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>