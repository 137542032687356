<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-5'"
        :dark="$vuetify.theme.dark"
        class=""
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            {{ title }}
          </h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="mainStyle">
      
      <div>
        <v-switch default-checked v-model="isUserSelf" :checked="isUserSelf" @change="onChangeIsUserSelf" />
        <span class="is-active">{{ isUserSelf ? 'Patient Only' : 'Staff' }}</span>
      </div>
      <div>
        <Answer 
          :questionSet="questionSet" 
          :targetQuestion="targetQuestion" 
          @selectedComplete="answerSelectedComplete" />

        <div class="BtnHeight">
          <v-btn color="primary" depressed large @click="handlePreNext('p')" :disabled="isStart" >Pre</v-btn>
          <div class="lengthBoxDiv">
            <v-btn v-if="isEnd" color="primary" depressed large @click="completeOk">COMPLETE</v-btn>
            <div v-else class="lengthBox">{{ step + 1 }} / {{ totalQuestion }}</div>
          </div>
          <v-btn color="primary" depressed large @click="handlePreNext('n')" 
            :disabled="isEnd">Next</v-btn>
        </div>
      </div>

      <ConfirmPassWord ref="refConfirmPW" 
        @clickCancel="clickCancelPassword"/>

    </div>

  </vue-perfect-scrollbar>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Answer from './Answer.vue';
import ConfirmPassWord from '@/components/commonV2/Dialog/DialogConfirmPassWord.vue';

const phqServiceTest = require('@/components/commonV2/inquiry/inquiryQuestionFile/PHQ9QuestionnaireTest.js');
const phqService = require('@/components/commonV2/inquiry/inquiryQuestionFile/PHQ9Questionnaire.js');
const healthService = require('@/components/commonV2/inquiry/inquiryQuestionFile/PatientHealthQuestionnaire.js');
const citService = require('@/components/commonV2/inquiry/inquiryQuestionFile/6CITQuestionnaire.js');

const formatService = require('@/utils/format.js');
const modelService = require('@/utils/dataModel.js');

export default {
  components: {
    Answer,
    ConfirmPassWord,
  },
  data() {
    return {
      title: '',
      isUserSelf: false,
      visible: false,
      confirmLoading: false,
      step: 0,
      totalQuestion: 0,
      questionSet: '',
      targetQuestion: null,
      userData: {},
      answerSet: [],
      surveySet: phqService.inquiryData(),
      reqData: modelService.patientSurveySetModel(),
    };
  },
  computed: {
    isStart() {
      return this.step === 0;
    },
    isEnd() {
      return this.totalQuestion - 1 === this.step
    },
    isComplete() {
      return this.totalQuestion - 1 === this.step
    }
  },
  methods: {
    _showModal(userData, set) {
      console.log('_showModal',userData, set);

      this.isUserSelf = false;
      this.visible = true;
      let titleUserName = 'New';
      if (userData !== null) {
        this.userData = userData;
        titleUserName = userData.name;
      }

      // 초기화
      this.totalQuestion = 0;
      this.step = 0;
      this.answerSet = [];

      if (set === 'Phq9Test') this.surveySet = phqServiceTest.inquiryData();
      if (set === 'PHQ9') this.surveySet = phqService.inquiryData();
      if (set === 'Health') this.surveySet = healthService.inquiryData();
      if (set === '6CIT') this.surveySet = citService.inquiryData();

      this.title = '(' + titleUserName + ') - ' + this.surveySet.surveyDefine + ' Survey Start';
      this.reqData.questionSet = this.surveySet.surveyTitle; // 저장용
      console.log('', set, this.surveySet);
      this.totalQuestion = this.surveySet.set.length;
      this.changeQuestion();
    },
    handlePreNext(mode) {
      if (mode === 'p') {
        if (this.step === 0) return;
        this.step--;
      }
      if (mode === 'n') {
        // 필수체크
        console.log('this.answerSet', this.answerSet);
        if (!this.checkTargetQuestion()) return;
        if (this.step === this.totalQuestion - 1) return;
        this.step++;
      }
      this.changeQuestion();
    },
    checkTargetQuestion() {
      if (this.answerSet[this.step] === undefined) {
        const msg = `Please select question ${this.step + 1}`;
        this.$helper.showTostMessage('bottom-right', msg, 'error');
        return false;
      }
      return true;
    },
    changeQuestion() {
      // console.log('changeQuestion', this.totalQuestion, this.step);
      this.targetQuestion = this.surveySet.set[this.step];
    },
    answerSelectedComplete(e) {
      this.answerSet[this.step] = e;
      const d = {
        questionSet: this.questionSet,
        answerSet: this.answerSet,
      };
      this.setUserSurvey(d);
    },
    onClose() {
      if (this.isUserSelf) {
        this.$refs.refConfirmPW.showModal();
        return;
      }
      this.clearUserSurvey();
      this.visible = false;
    },
    onChangeIsUserSelf(checked) {
      this.isUserSelf = checked;
      if (checked) this.$refs.refConfirmPW.showModal();
    },
    completeOk() {
      // 필수 마지막 설문확인
      if (!this.checkTargetQuestion()) return;
      if (!confirm('Save?')) return;
      // 전송데이터 정리
      this.reqData.user_id = this.userData.user_id;
      this.reqData.hospital_id = this.userData.hospital_id;
      this.reqData.answers = this.answerSet.toString();
      console.log('this.reqData', this.reqData);
      this.saveAction();
    },
    async saveAction() {
      await this.saveDataQuery(this.reqData).then((res) => {
        if (res === undefined) return;
        this.$helper.showTostMessage('bottom-right', this.$helper.commonMessage('S'), 'success');
        this.visible = false;
        this.clearUserSurvey();
        this.$emit('complete', res);
      });
    },
    /** password set */
    clickCancelPassword() {
      this.isUserSelf = false;
    },
    ...mapActions(['setUserSurvey', 'clearUserSurvey', 'getDataQuery', 'saveDataQuery']),
  },
};
</script>

<style lang="scss">
.is-active {
  display: inline-block;
  vertical-align: middle;
  padding: 0 10px;
}
.BtnHeight {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  height: 100px;
  text-align: center;
}
.lengthBoxDiv {
  width: 200px;
}
.lengthBox {
  width: 200px;
  text-align: center;
}
.ant-list {
  .last-item {
    align-items: stretch;
  }
}
</style>./SurveySetAnswer.vue./SurveySetConfirmPassWord.vue